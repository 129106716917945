// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home'; // Exemplo de página
import About from './pages/About'; // Exemplo de página
import Conversar from './pages/conversar'; // Exemplo de páginaSolucao
import Solucao from './pages/Solucao'; // Exemplo de página

function App() {
  return (
    <Router>
      <Header />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/conversar" element={<Conversar />} />
          <Route path="/Solucao" element={<Solucao />} /> 
          {/* Add other routes here */}
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
