import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    console.log('Hamburger clicked!'); // Para verificar se o clique está funcionando
    setMenuOpen(!menuOpen);
  };
 
  

  return (
    <header className="header">
      <div className="container">
        <a href="/">
          <div className="logo">
            <img width="150" height="52" src="img/logoKronalisLaranjaBranco.png" alt="Logo Progic" />
          </div>
        </a>
        <div className={`menu ${menuOpen ? 'open' : ''}`}>
          <ul>
            <li><a href="/Solucao">Solução</a></li>
            <li><a href="#">Aplicação</a></li>
            
            <li><a href="#">Conteúdos</a></li>
            <li><a href="#">Parceiros</a></li>
            {/* Adicione os botões de "Entre em contato" e "Login" no menu */}
            <li><a href="/conversar" className="btn gradient">Entre em contato</a></li>
            <li><a href="#" className="btn">Login</a></li>
          </ul>
        </div>
        <div className="hamburger" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </div>
    </header>
  );
}

export default Header;
