import React from 'react';
import './Solucao.css';
import { FaLaptopCode, FaChartLine, FaCogs, FaWarehouse, FaRobot } from 'react-icons/fa';

function Solucao() {
  return (
    <div className="solucao-section">
      <div className="solucao-header">
        <h1 className="solucao-title">Nossas Soluções</h1>
        <p className="solucao-description">Transforme seu negócio com as soluções inovadoras da Kronalis</p>
      </div>
      
      <div className="solucoes-grid">
        <div className="solucao-card">
          <FaLaptopCode className="solucao-icon" />
          <h3>Criação de Sites Personalizados</h3>
          <p>Soluções sob medida para destacar sua empresa no ambiente digital.</p>
        </div>

        <div className="solucao-card">
          <FaCogs className="solucao-icon" />
          <h3>Sistemas Próprios</h3>
          <p>Desenvolvemos sistemas completos, personalizados para sua necessidade.</p>
        </div>

        <div className="solucao-card">
          <FaChartLine className="solucao-icon" />
          <h3>Folha de Pagamento</h3>
          <p>Automatize sua folha de pagamento com nosso sistema eficiente e fácil de usar.</p>
        </div>

        <div className="solucao-card">
          <FaWarehouse className="solucao-icon" />
          <h3>Gestão de Estoque</h3>
          <p>Controle seu estoque com precisão e facilidade.</p>
        </div>

        <div className="solucao-card">
          <FaRobot className="solucao-icon" />
          <h3>Automatizações</h3>
          <p>Implemente automações para aumentar a produtividade e eficiência.</p>
        </div>
      </div>
    </div>
  );
}

export default Solucao;
