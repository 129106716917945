import React, { useState } from 'react';
import './Home.css'; // Certifique-se de que o arquivo CSS esteja correto
import BlocoAbas from '../components/BlocoAbas';
import DevemosTrabalhar from '../components/DevemosTrabalhar';
function Home() {
  

  return (
    <>
      <div className="home-container">
        <div className="banner">
          <div className="bubbles">
            <img src="img/Dado.png" alt="Bubble 1" className="bubble bubble1" />
            <img src="img/Dado.png" alt="Bubble 2" className="bubble bubble2" />
            <img src="img/Dado2.png" alt="Bubble 3" className="bubble bubble3" />
            <img src="img/Dado3.png" alt="Bubble 4" className="bubble bubble4" />
            <img src="img/Dado4.png" alt="Bubble 5" className="bubble bubble5" />
          </div> 
          <h1>Nós criamos soluções que vão além da tecnologia.</h1>
          <h2>Nós criamos <span className="highlighted">experiências</span>.</h2>
          <p className="call-to-action">Deseja criar seu site ou software?</p>
          <a className="contact-button" href="/conversar">Vamos conversar</a>
          
        </div>
      </div>
      <BlocoAbas />

      <DevemosTrabalhar />
    </>
  );
}

export default Home;
