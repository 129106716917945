import React, { useState } from 'react';
import './Conversar.css'; // Arquivo de estilos para o layout

function Conversar() {
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    telefone: '',
    empresa: '',
    numeroFuncionarios: '',
    objetivoPrincipal: '',
    mensagem: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Adicione aqui a lógica de envio do formulário
  };

  return (
    <div className="pai-conversar-form-container">
      <div className="conversar-form-container">
        <h2 className="conversar-form-title">Converse com Nossa Equipe Especializada</h2>
        <p className="conversar-form-subtitle">Agende uma conversa personalizada 1:1 com nossas consultoras e esclareça todas as suas dúvidas.</p>

        <form className="conversar-form" onSubmit={handleSubmit}>
          <div className="conversar-form-group">
            <label className="conversar-form-label">Nome Completo*</label>
            <input
              type="text"
              name="nome"
              value={formData.nome}
              onChange={handleChange}
              className="conversar-form-input"
              placeholder="Digite seu nome completo"
              required
            />
          </div>

          <div className="conversar-form-group">
            <label className="conversar-form-label">E-mail*</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="conversar-form-input"
              placeholder="Digite seu e-mail de contato"
              required
            />
          </div>

          <div className="conversar-form-group">
            <label className="conversar-form-label">Telefone*</label>
            <input
              type="tel"
              name="telefone"
              value={formData.telefone}
              onChange={handleChange}
              className="conversar-form-input"
              placeholder="Digite seu telefone para contato"
              required
            />
          </div>

          <div className="conversar-form-group">
            <label className="conversar-form-label">Nome da Empresa*</label>
            <input
              type="text"
              name="empresa"
              value={formData.empresa}
              onChange={handleChange}
              className="conversar-form-input"
              placeholder="Digite o nome da sua empresa"
              required
            />
          </div>

          <div className="conversar-form-group">
            <label className="conversar-form-label">Número de Funcionários*</label>
            <input
              type="number"
              name="numeroFuncionarios"
              value={formData.numeroFuncionarios}
              onChange={handleChange}
              className="conversar-form-input"
              placeholder="Informe o número total de colaboradores"
              required
            />
          </div>

          <div className="conversar-form-group">
            <label className="conversar-form-label">Qual é o Seu Objetivo Principal?*</label>
            <select
              name="objetivoPrincipal"
              value={formData.objetivoPrincipal}
              onChange={handleChange}
              className="conversar-form-select"
              required
            >
              <option value="">Selecione uma opção</option>
              <option value="comunicacaoColaboradores">Melhorar a comunicação com colaboradores</option>
              <option value="comunicacaoClientes">Melhorar a comunicação com clientes</option>
            </select>
          </div>

          <div className="conversar-form-group">
            <label className="conversar-form-label">Mensagem*</label>
            <textarea
              name="mensagem"
              value={formData.mensagem}
              onChange={handleChange}
              className="conversar-form-textarea"
              placeholder="Compartilhe suas expectativas e dúvidas aqui"
              required
            />
          </div>

          <button type="submit" className="conversar-form-button">Enviar Solicitação</button>
        </form>
      </div>
    </div>
  );
}

export default Conversar;
