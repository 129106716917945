// src/components/BlocoAbas.js

import React, { useState } from 'react';
import './BlocoAbas.css'; // Certifique-se de criar este arquivo CSS separado
import softwareIcon from './icons/software.png'; // Exemplo de importação de ícone SVG
import webIcon from './icons/website.png';
import appIcon from './icons/mobile-development.png';
import automationIcon from './icons/robotic-process-automation.png';

function BlocoAbas() {
  const [activeTab, setActiveTab] = useState('tech-solutions_a');

  const openTab = (event, tabId) => {
    event.preventDefault();
    setActiveTab(tabId);
  };

  return (
    <section className="bloco_abas">
      <div className="container">
        <h2>Nossas Soluções Tecnológicas</h2>
        <div className="tab-container">
          <div className="tab">
            <a
              className={`tablinks ${activeTab === 'tech-solutions_a' ? 'active' : ''}`}
              onClick={(e) => openTab(e, 'tech-solutions_a')}
            >
              <img className="tab-icon" src={softwareIcon} alt="Desenvolvimento de Software" />
              Desenvolvimento de Software
            </a>
            <a
              className={`tablinks ${activeTab === 'tech-solutions_b' ? 'active' : ''}`}
              onClick={(e) => openTab(e, 'tech-solutions_b')}
            >
              <img className="tab-icon" src={webIcon} alt="Criação de Sites" />
              Criação de Sites
            </a>
            <a
              className={`tablinks ${activeTab === 'tech-solutions_c' ? 'active' : ''}`}
              onClick={(e) => openTab(e, 'tech-solutions_c')}
            >
              <img className="tab-icon" src={appIcon} alt="Desenvolvimento de Apps" />
              Desenvolvimento de Apps
            </a>
            <a
              className={`tablinks ${activeTab === 'tech-solutions_d' ? 'active' : ''}`}
              onClick={(e) => openTab(e, 'tech-solutions_d')}
            >
              <img className="tab-icon" src={automationIcon} alt="Automatização de Processos" />
              Automatização de Processos
            </a>
          </div>

          <div className={`tabcontent ${activeTab === 'tech-solutions_a' ? 'active' : ''}`}>
            <div className="tab-content-wrapper">
              <div className="texto">
                <h3>Desenvolvimento de Software</h3>
                <p>Criamos soluções de software sob medida para atender às suas necessidades específicas.</p>
                <ul>
                  <li>Sistemas personalizados</li>
                  <li>Integração com outras plataformas</li>
                  <li>Suporte contínuo e manutenção</li>
                </ul>
                <a className="btn" href="https://example.com/software-solutions">Saiba mais</a>
              </div>
              <div className="imagem">
                <img width="600" height="470" alt="Desenvolvimento de Software" src="img/1-4-removebg-preview.png" />
              </div>
            </div>
          </div>

          <div className={`tabcontent ${activeTab === 'tech-solutions_b' ? 'active' : ''}`}>
            <div className="tab-content-wrapper">
              <div className="texto">
                <h3>Criação de Sites</h3>
                <p>Desenvolvemos sites modernos e responsivos que destacam sua marca e atraem clientes.</p>
                <ul>
                  <li>Design responsivo e otimizado</li>
                  <li>SEO e usabilidade aprimorados</li>
                  <li>Integração com ferramentas de marketing</li>
                </ul>
                <a className="btn" href="https://example.com/web-development">Saiba mais</a>
              </div>
              <div className="imagem">
                <img width="600" height="470" alt="Criação de Sites" src="img/desenvolvimento-de-software-removebg-preview.png" /> 
              </div>
            </div>
          </div>

          <div className={`tabcontent ${activeTab === 'tech-solutions_c' ? 'active' : ''}`}>
            <div className="tab-content-wrapper">
              <div className="texto">
                <h3>Desenvolvimento de Apps</h3>
                <p>Desenvolvemos aplicativos móveis que proporcionam uma experiência de usuário excepcional.</p>
                <ul>
                  <li>Apps nativos e híbridos</li>
                  <li>Integração com APIs e serviços externos</li>
                  <li>Otimização para desempenho e segurança</li>
                </ul>
                <a className="btn" href="https://example.com/app-development">Saiba mais</a>
              </div>
              <div className="imagem">
                <img width="600" height="470"  alt="Desenvolvimento de Apps" src="img/ImgApp.png" /> 
              </div>
            </div>
          </div>

          <div className={`tabcontent ${activeTab === 'tech-solutions_d' ? 'active' : ''}`}>
            <div className="tab-content-wrapper">
              <div className="texto">
                <h3>Automatização de Processos</h3>
                <p>Implementamos soluções de automatização para melhorar a eficiência e reduzir erros.</p>
                <ul>
                  <li>Automatização de fluxos de trabalho</li>
                  <li>Integração com sistemas existentes</li>
                  <li>Monitoramento e relatórios em tempo real</li>
                </ul>
                <a className="btn" href="https://example.com/automation">Saiba mais</a>
              </div>
              <div className="imagem">
                <img width="600" height="470" alt="Automatização de Processos" src="img/image-removebg-preview (22).png" />
              </div>
            </div>
          </div>
        </div>
        </div>
    
    </section>
  );
}

export default BlocoAbas; 
