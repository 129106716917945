import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram, faYoutube, faSpotify } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="contato">
          <img width="150" height="70" className="logo" src="img/logoKronalisLaranjaBranco.png" alt="Logo Progic" />
          <p>Nós ajudamos a promover o clima, a cultura e o engajamento da sua empresa através de uma Plataforma Multicanal de Comunicação Corporativa.</p>
          <div className="selos">
            <img width="250" height="123" alt="selo" src="https://progic.com.br/wp-content/uploads/2024/07/Selo-GPTW-ACATE-2024-1.png" />
            <div className="clear"></div>
          </div>
          <h2>Connect your people</h2>
          <div className="social">
            <a href="https://linkedin.com" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a href="https://youtube.com" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faYoutube} size="2x" />
            </a>
            <a href="https://spotify.com" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faSpotify} size="2x" />
            </a>
          </div>
          <h3>+55 19 9815 41198</h3>
        </div>
        <div className="menu">
          <div className="coluna">
            <h2>Solução</h2>
            <ul>
              <li><a href="#">Plataforma</a></li>
              <li><a href="#">TV Corporativa</a></li>
              <li><a href="#">Aplicativo</a></li>
              <li><a href="#">Biblioteca</a></li>
              <li><a href="#">Serviços profissionais</a></li>
              <li><a href="#">Player</a></li>
              <li><a href="#">Login</a></li>
            </ul>
          </div>
          <div className="coluna">
            <h2>Aplicações</h2>
            <ul>
              <li><a href="#">Indústria</a></li>
              <li><a href="#">Educação</a></li>
              <li><a href="#">Logística</a></li>
              <li><a href="#">Setor Financeiro</a></li>
              <li><a href="#">Histórias de sucesso</a></li>
            </ul>
          </div>
          <div className="coluna">
            <h2>Conheça a Kronalis</h2>
            <ul>
              <li><a href="#">A Kronalis</a></li>
              <li><a href="#">Central de recursos</a></li>
              <li><a href="#">Novidades</a></li>
              <li><a href="#">Endomarketing.tv</a></li>
              <li><a href="#">Contato</a></li>
              <li><a href="#">Política de privacidade</a></li>
            </ul>
          </div>
        </div>
        <div className="clear"></div>
        <div className="politicas">
          <p>Kronalis. Copyright @ 2008-2024.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
