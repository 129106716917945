import React from 'react';
import './DevemosTrabalhar.css'; // Arquivo CSS específico para o estilo

const DevemosTrabalhar = () => {
  return (
    <>
      <section className="parceiro_ideal">
        <div className="topo">
          <div className="container">
            <img width="163" height="175" className="a" src="img/Dado4.png" alt="Background imagem bolhas" />
            <h2>Somos o parceiro ideal para suas soluções digitais</h2>
            <h3><span className="textgradient">Por que grandes empresas escolhem nossos serviços?</span></h3>
            <p><b>Desenvolvimento de software e sites não é tudo igual.</b> Com anos de experiência e foco total em inovação, somos líderes no desenvolvimento de soluções tecnológicas sob medida para os seus desafios.</p>
            <img width="156" height="158" className="b" src="img/Dado2.png" alt="Background imagem bolhas" />
            <div className="clear"></div>
          </div>
        </div>
        <div className="vantagens">
          <div className="container container222">
            <div className="box">
              <img width="59" height="60" alt="Ícone vantagem" src="img/pngtree-yellow-check-mark-icon-right-side-png-image_12530523-removebg-preview.png" /> 
              <h4>Metodologia Inovadora</h4>
              <p>Nossa abordagem personalizada garante que cada projeto seja único e focado nas reais necessidades de nossos clientes.</p>
            </div>
            <div className="box">
              <img width="50" height="59" alt="Ícone vantagem" src="img/5466151-removebg-preview.png" />
              <h4>Suporte Especializado</h4>
              <p>Oferecemos suporte dedicado para que seus projetos sejam bem-sucedidos do início ao fim.</p>
            </div>
            <div className="box">
              <img width="59" height="59" alt="Ícone vantagem" src="img/10103186-removebg-preview.png" />
              <h4>Excelência no Desenvolvimento</h4>
              <p>Somos reconhecidos pela qualidade e eficiência no desenvolvimento de software e sites personalizados.</p>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </section>

      {/* Nova seção */}
      <section className="cta_simples cta_simples_btn">
        <div className="container">
          <img width="163" height="175" className="a" src="img/Dado3.png" alt="Background imagem bolhas" />
          <h2>Vamos <span className="textgradient">conversar?</span></h2>
          <p>Conecte suas pessoas, conteúdos e tecnologia em uma experiência digital única.</p>
          <a className="btn gradient" href="/conversar">Agende uma demo agora!</a>
          <a href="https://wa.me/5519981541198"><p>Ou ligue: +55 (19) 98154-1198</p></a>
          
          <img width="156" height="158" className="b" src="img/Dado.png" alt="Background imagem bolhas" />
          <div className="clear"></div>
        </div>
      </section>
    </>
  );
};

export default DevemosTrabalhar;
